<template>
  <div>
    <div class="pageWrapper">
      <div class="topbar">
        <div style="display: flex">
          <H2>New Orders</H2>
        </div>
      </div>
      <SearchInput placeholder="Search" v-model="searchString" />
      <div style="margin-top: 10px">
        <select class="dropDownSelect" v-model="deliveryMethodSelect">
          <option :value="null">All</option>
          <option value="hemleverans">Hemleverans</option>
          <option value="drive_through">Drive through</option>
          <option value="in_store">In store</option>
        </select>
        <ShopPicker />
      </div>
      <fragment>
        <table class="standardTable">
          <br />
          <tr class="standardTableHeader">
            <th></th>
            <th v-if="!isMobile">Pickup date</th>
            <th v-if="!isMobile">Amount products</th>
            <th>Customer</th>
            <!-- <th v-if="!isMobile">Total</th> -->
            <th v-if="!isMobile && deliveryMethodSelect != 'hemleverans'">
              Store
            </th>
            <th>Confirm</th>
            <th>Reject</th>
          </tr>
          <br v-if="!orders" />
          <LoadingRowTable :height="18" :dataList="orders" :columnsAmount="7">
            <OrderItem
              :deliveryMethodSelect="deliveryMethodSelect"
              :key="i"
              :order="order"
              :prevOrder="orders[i - 1]"
              v-for="(order, i) in filteredOrders"
              :removeOrder="removeOrder"
            />
          </LoadingRowTable>
        </table>
      </fragment>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import OrderItem from '@/components/orders/OrderItem'
import LoadingRowTable from '@/components/LoadingRowTable'
import ShopPicker from '@/components/ShopPicker'
import SearchInput from '../../layout/SearchInput'
import H2 from '../../layout/typography/H2'

export default {
  components: {
    OrderItem,
    LoadingRowTable,
    ShopPicker,
    SearchInput,
    H2,
  },
  props: ['displayType'],
  data() {
    return {
      orders: undefined,
      searchString: '',
      statusMobileMenu: false,
      shops: undefined,
      storeSelect: null,
      deliveryMethodSelect: null,
      bulkSelect: false,
      archived: 0,
      bulkMenuButtonStyle: {
        top: '2px',
        position: 'relative',
        borderRadius: '25px',
        marginLeft: '15px',
        background: '#444',
        border: 'none',
      },
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    filteredOrders() {
      if (this.orders) {
        const search = this.searchString.toLowerCase().trim()

        return this.orders
          .filter((order) => {
            if (JSON.stringify(order).toLowerCase().indexOf(search) > -1) {
              return true
            }
          })
          .filter((order) => {
            if (this.$store.state.selectedShops?.length > 0) {
              if (this.$store.state.selectedShops.includes(order.shopId)) {
                return true
              }
            } else {
              return true
            }
          })
      } else {
        return []
      }
    },
  },
  created() {
    if (this.displayType == 'all') {
      this.archived = 'all'
    }
    this.getOrders()
  },
  methods: {
    getOrders() {
      axios.post('orders/getOrders', {
        companyId: this.$store.state.selectedUser.companyId,
        archived: this.archived,
        confirmed: 0,
        deliveryMethod: this.deliveryMethodSelect,
        customerId: this.$route.params.customerId,
      })
        .then(res => {
          this.orders = res.data
        })
        .catch(err => {
          console.log(err);
        })
    },
    jst(input) {
      return JSON.stringify(input)
    },
    removeOrder(data) {
      this.orders.forEach((order, i) => {
        if (order.orderId == data) {
          this.orders.splice(i, 1)
        }
      })
    },
    toggleMobileMenu() {
      this.statusMobileMenu = !this.statusMobileMenu
    },
    gotoView(page) {
      this.$router.push({ name: page })
    },
  },
}
</script>

<style scoped lang="scss">
@import url('../../assets/css/table.css');

.standardTableHeader {
  transform: translateY(20px);
}

.bulkMenuWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.standardTable {
  table-layout: auto;
}

.bulkMenu {
  display: flex;
  position: fixed;
  transition: 0.2s;
  z-index: 12;
  bottom: 50px;
  color: white;
  background: rgb(37, 37, 37);
  padding: 15px;
  transform: translateY(200px);
  border-radius: 35px;
}

/* @media screen and (max-width: 700px) {
  .bulkMenu {
    display: flex;
    position: fixed;
    transition: 0.2s;
    z-index: 12;
    bottom: 70px;
    color: white;
    background: rgb(37, 37, 37);
    padding: 15px;
    transform: translateY(200px);
    border-radius: 35px;
    margin: auto;
  }
} */

.bulkMenu div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuButton {
  padding: 5px 8px 2px 8px;
  background: #2694de;
  border-radius: 3px;
  border: none;
  color: white;
  font-family: 'Spartan';
  font-size: 9pt;
  width: 100%;
}

.mobileMenu {
  border: solid 1px #c0c0c0;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  right: 10px;
  background: #eaeaea;
}

.mobileMenu button {
  display: block;
  width: 100%;
  border: none;
  background: none;
  font-size: 10pt;
  border-bottom: 1px solid #c0c0c0;
}

.mobileMenu button:last-child {
  border-bottom: none;
}
</style>